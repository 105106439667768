import {
  toMappingItem,
  type Mapping,
  type MappingItem,
} from '@pn/core/domain/data';
import { nullToUndefined } from '@pn/core/utils/logic';
import type { ParquetMappingItem, ParquetSourceType } from './types';

/**
 * @link https://github.com/ankane/polars-ruby?tab=readme-ov-file#types
 */
function parquetSourceTypeToDomainType(
  sourceType: ParquetSourceType
): MappingItem['domainType'] {
  switch (sourceType) {
    case 'Boolean':
      return 'boolean';
    case 'Float32':
    case 'Float64':
    case 'Int8':
    case 'Int16':
    case 'Int32':
    case 'Int64':
      return 'number';
    case 'String':
      return 'string';
    case 'Date':
      return 'DateString';
    case 'WKTString': // special type
      return 'WKT';
    default:
      throw new Error(`Invalid parquet source type: ${sourceType}`);
  }
}

export const parquetToDomainMapping = (
  parquetMapping: Record<string, ParquetMappingItem>
): Mapping => {
  return Object.entries(parquetMapping).reduce<Mapping>(
    (acc, [field, { data_type, ...item }]) => {
      if (data_type === 'Null') return acc;

      const domainType = parquetSourceTypeToDomainType(data_type);

      acc[field] = toMappingItem({
        field,
        label: field,
        domainType,
        domainTypeAttributes:
          domainType === 'DateString' ? { format: 'yyyy-MM-dd' } : undefined,
        isShownByDefault: true,
        isNotRenderable: nullToUndefined(item.is_not_renderable),
        source: {
          type: data_type,
        },
      });

      return acc;
    },
    {}
  );
};

import { buildQuery } from '@pn/core/domain/query';
import {
  createInitInternalState,
  getLayerId,
  type ApiDataSource,
  type GeoJsonDataSource,
  type IWorkspaceItemMapper,
  type NoDataSource,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';
import { getCurrentUserId } from '@pn/core/storage/user/currentUserStorage';
import { nullToUndefined } from '@pn/core/utils/logic';
import { parquetToDomainMapping } from '@pn/services/api/data/parquet';
import { apiProjectUserMapper } from '@pn/services/api/user/apiUserMapper';
import { generateGeoJSONFeatureCollection } from '@pn/services/map/mapbox/mapboxUtils';
import { mapboxLayerMapper } from '@pn/services/map/mapbox/mappers/mapboxLayerMapper';
import assert from 'assert';
import { isNil, isString } from 'lodash-es';
import type { ApiLayerItem, ApiLayerItemPayload } from './types';

export const apiLayerItemMapper: IWorkspaceItemMapper<
  ApiLayerItem,
  ApiLayerItemPayload
> = {
  toWorkspaceItem: (apiLayerItem) => {
    assert(
      apiLayerItem.layers_definition,
      `layers_definition is missing in ${apiLayerItem.id}`
    );

    return {
      folder: getFolder(apiLayerItem),
      isTemporary: apiLayerItem.origin === 'stackdx', // hide Stack source layers from the Library
      id: apiLayerItem.id,
      dataType: apiLayerItem.id,
      name: apiLayerItem.name,
      numberOfElements: nullToUndefined(apiLayerItem.items_length),
      colorIndicator: nullToUndefined(apiLayerItem.color_indicator),
      itemType: 'layer',
      origin: apiLayerItem.origin,
      createdAt: apiLayerItem.created_at,
      updatedAt: apiLayerItem.updated_at,
      createdBy: !isNil(apiLayerItem.created_by)
        ? apiProjectUserMapper.toDomainProjectUser(apiLayerItem.created_by)
        : undefined,
      isShared: apiLayerItem.shared,
      isGlobal: apiLayerItem.global,
      map: {
        layers: apiLayerItem.layers_definition.map((mapboxLayer, index) => ({
          name: mapboxLayer.name,
          ...mapboxLayerMapper.toDomainLayer(
            {
              source: {
                type: 'geojson',
                data: generateGeoJSONFeatureCollection([]),
              },
              'source-layer': undefined,
              ...mapboxLayer, // will override the source if one is present
              id: getLayerId(apiLayerItem.id, apiLayerItem.id, index),
            },
            mapboxLayer.render_as_points ?? false
          ),
        })),
      },
      dataSource: mapToDomainDataSource(apiLayerItem),
      detailsSource: apiLayerItem.has_api_details ? 'api' : 'local',
      query: buildQuery({
        id: apiLayerItem.id,
        dataType: apiLayerItem.id,
      }),
      ...createInitInternalState({
        isVisualized: false,
        mapping: !isNil(apiLayerItem.mapping)
          ? parquetToDomainMapping(apiLayerItem.mapping)
          : {},
      }),
    };
  },
  toOriginalItem: (item) => {
    return {
      name: item.name,
      layers_definition: item.map.layers.map(mapboxLayerMapper.toTargetLayer),
    };
  },
};

function getFolder(apiLayerItem: ApiLayerItem): WorkspaceItem['folder'] {
  const userId = getCurrentUserId(); // HACK

  if (apiLayerItem.global) {
    return undefined; // do not show in the Library outside of projects
  } else if (apiLayerItem.origin === 'stackdx') {
    return 'StackDX';
  } else if (!isNil(userId) && apiLayerItem.created_by?.id === userId) {
    return 'Personal';
  } else {
    return apiLayerItem.shared ? 'Shared' : undefined;
  }
}

function mapToDomainDataSource(
  apiLayerItem: ApiLayerItem
): ApiDataSource | GeoJsonDataSource | NoDataSource {
  const { id, data_source_type, data_source_url, data_source_map_fields } =
    apiLayerItem;

  switch (data_source_type) {
    case 'elastic':
    case 'postgres':
    case 'parquet':
      assert(
        isString(data_source_url),
        `Failed to map ${id}: data_source_url must be a string`
      );
      return {
        type: 'api',
        source: data_source_type,
        url: data_source_url,
        requiredMapDataFields: data_source_map_fields,
      };
    case 'geojson':
      assert(
        isString(data_source_url),
        `Failed to map ${id}: data_source_url must be a string`
      );
      return {
        type: 'geojson',
        url: data_source_url,
      };
    case 'none':
      return {
        type: 'none',
      };
  }
}

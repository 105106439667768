import { dependencies } from '@pn/core/dependencies';
import {
  getIntelPermissionLevel,
  IntelPlans,
  User,
} from '@pn/core/domain/user';
import { useCurrentUserStorage } from '@pn/core/storage';
import { get, isNil, isObject } from 'lodash-es';
import React from 'react';

type IntelPermission = {
  minimumPlan: IntelPlans;
  limits?: {
    [key in IntelPlans]: number;
  };
  message?: string;
};

interface NestedPermissions {
  [x: string]: NestedPermissions | IntelPermission;
}

type Access = {
  notify(silent?: boolean): Omit<Access, 'notify'>;
  denied(): boolean;
  granted(): boolean;
  message: string;
};

/**
 * // FIXME why would you copy-paste this?
 * @description Used to check for access to paid for intel features
 * @note Logged out users have starter plan
 * @returns
 */
export function useIntelAccess() {
  const {
    notificationService: { notify: displayNotification },
  } = dependencies;

  const { user } = useCurrentUserStorage();
  const userIntelPlan = user?.intelPlan || IntelPlans.Starter; // logged out users have starter plan

  return React.useCallback(
    (path: string): Access => {
      const permission = getPermissionByPath(path);

      /* Handle insufficient permissions */
      if (
        !isNil(permission) &&
        isInsufficientPlan(userIntelPlan, permission.minimumPlan)
      ) {
        const message =
          permission.message ?? 'Your plan does not support this feature';

        const notify: Access['notify'] = (silent = false) => {
          if (!silent) displayNotification(message, 'warning');
          return {
            denied: () => true,
            granted: () => false,
            message,
          };
        };

        return {
          notify,
          ...notify(true),
        };
      }

      const notify: Access['notify'] = () => {
        // no alerts needed here
        return {
          denied: () => false,
          granted: () => true,
          message: '',
        };
      };

      return {
        notify,
        ...notify(),
      };
    },
    [displayNotification, userIntelPlan]
  );
}

function isInsufficientPlan(
  userIntelPlan: IntelPlans | undefined,
  minimumPlan: IntelPlans
): boolean {
  return (
    getIntelPermissionLevel(userIntelPlan) <
    getIntelPermissionLevel(minimumPlan)
  );
}

const permissionsConfiguration: NestedPermissions = {
  company: {
    history: {
      minimumPlan: IntelPlans.Retail,
      message: 'Upgrade your account to access company history',
    },
    charts: {
      minimumPlan: IntelPlans.Retail,
      message: 'Upgrade your account to access company charts',
    },
  },
  bas: {
    minimumPlan: IntelPlans.Enterprise,
    message: 'Upgrade your account to access BAS',
  },
  companies: {
    minimumPlan: IntelPlans.Professional,
    message: 'Upgrade your account to access companies',
  },
  charts: {
    minimumPlan: IntelPlans.Retail,
    message: 'Upgrade your account to access charts',
  },
  exporting: {
    minimumPlan: IntelPlans.Professional,
    message: 'Upgrade your account to access exporting',
  },
  kpis: {
    minimumPlan: IntelPlans.Retail,
    message: 'Upgrade your account to access KPIs',
  },
  areas: {
    minimumPlan: IntelPlans.Retail,
    message: 'Upgrade your account to access areas',
  },
  alerts: {
    minimumPlan: IntelPlans.Enterprise,
    message: 'Upgrade your account to access alerts',
  },
  mismatched_licenses: {
    minimumPlan: IntelPlans.Professional,
    message: 'Upgrade your account to access mismatched licences',
  },
  // named in snake to match the API data type
  land_results: {
    minimumPlan: IntelPlans.Starter,
    message: 'Upgrade your account to access land results',
  },
  // named in snake to match the API data type
  land_postings: {
    minimumPlan: IntelPlans.Starter,
    message: 'Upgrade your account to access land postings',
  },
  // named in snake to match the API data type
  aer_transfers: {
    minimumPlan: IntelPlans.Starter,
    message: 'Upgrade your account to access AER transfers',
    map: {
      minimumPlan: IntelPlans.Retail,
      message: 'Upgrade your account to map AER transfers',
    },
  },
  // named in snake to match the API data type
  well_licenses: {
    minimumPlan: IntelPlans.Starter,
    message: 'Upgrade your account to access well licences',
  },
  // named in snake to match the API data type
  mineral_rights_transfers: {
    minimumPlan: IntelPlans.Professional,
    message: 'Upgrade your account to access mineral rights transfers',
  },
  // named in snake to match the API data type
  well_license_transfers: {
    minimumPlan: IntelPlans.Professional,
    message: 'Upgrade your account to access well licence transfers',
  },
  wells_by_liquid_production: {
    minimumPlan: IntelPlans.Professional,
    message: 'Upgrade your account to access top wells by liquid production',
  },
  confidential_wells: {
    minimumPlan: IntelPlans.Enterprise,
    message: 'Upgrade your account to access confidential wells',
  },
  declassified_wells: {
    minimumPlan: IntelPlans.Enterprise,
    message: 'Upgrade your account to access declassified wells',
  },
  wells_by_production: {
    minimumPlan: IntelPlans.Professional,
    message: 'Upgrade your account to access top wells by production',
  },
  wells_by_gas_production: {
    minimumPlan: IntelPlans.Professional,
    message: 'Upgrade your account to access top wells by gas production',
  },
  well_licence_mismatches: {
    minimumPlan: IntelPlans.Professional,
    message: 'Upgrade your account to access well licence mismatches',
  },
  liquid_ip3: {
    minimumPlan: IntelPlans.Professional,
    message:
      'Upgrade your account to access wells by initial liquid production',
  },
  liquid_ip12: {
    minimumPlan: IntelPlans.Professional,
    message:
      'Upgrade your account to access wells by initial liquid production',
  },
  gas_ip3: {
    minimumPlan: IntelPlans.Professional,
    message: 'Upgrade your account to access wells by initial gas production',
  },
  gas_ip12: {
    minimumPlan: IntelPlans.Professional,
    message: 'Upgrade your account to access wells by initial gas production',
  },
  plotAERTransfers: {
    minimumPlan: IntelPlans.Professional,
    message: 'Upgrade your account to plot AER transfers on the map',
  },
  monthlyStats: {
    minimumPlan: IntelPlans.Professional,
    message: 'Upgrade your account to see monthly stats',
  },
  reports: {
    minimumPlan: IntelPlans.Professional,
    message: 'Upgrade your account to access intel-templates',
  },
};

function isPermission(arg: unknown): arg is IntelPermission {
  return isObject(arg) && 'minimumPlan' in arg;
}

function getPermissionByPath(path: string): IntelPermission | undefined {
  const permission = get(permissionsConfiguration, path);

  if (isPermission(permission)) {
    return permission;
  } else {
    return undefined;
  }
}

function hasExportAccessToMarketIntel(user: User) {
  return (
    user.intelPlan === IntelPlans.Professional ||
    user.intelPlan === IntelPlans.Enterprise
  );
}

function hasGeneralAccessToMarketIntel(user: User) {
  return (
    user.intelPlan === IntelPlans.Retail || hasExportAccessToMarketIntel(user)
  );
}

export function useAccessDeniedMarketIntel() {
  const { useAuthenticationService } = dependencies;

  const { user } = useCurrentUserStorage();
  const { isAuthenticating, isAuthenticated } = useAuthenticationService();

  const isLoading = isAuthenticating || isNil(user);

  return React.useMemo(() => {
    return {
      isAccessDeniedToFree: isLoading || !isAuthenticated || isNil(user),
      isAccessDeniedToBasic:
        isLoading || !isAuthenticated || isNil(user)
          ? true
          : !hasGeneralAccessToMarketIntel(user),
      isAccessDeniedToExporting:
        isLoading || !isAuthenticated || isNil(user)
          ? true
          : !hasExportAccessToMarketIntel(user),
    };
  }, [isAuthenticated, isLoading, user]);
}

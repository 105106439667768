import {
  toMappingItem,
  type Mapping,
  type MappingItem,
} from '@pn/core/domain/data';
import { isNil } from 'lodash-es';
import type { ElasticMappingItem, ElasticSourceType } from './types';

function elasticSourceTypeToDomainType(
  sourceType: ElasticSourceType
): MappingItem['domainType'] {
  switch (sourceType) {
    case 'boolean':
      return 'boolean';
    case 'integer':
    case 'double':
      return 'number';
    case 'keyword':
    case 'text':
      return 'string';
    case 'date':
      return 'DateString';
    case 'geo_shape':
      return 'GeoShape';
    default:
      throw new Error(`Invalid elastic source type: ${sourceType}`);
  }
}

export const elasticToDomainMapping = (
  elasticMapping: Record<string, ElasticMappingItem>
): Mapping => {
  return Object.entries(elasticMapping).reduce<Mapping>(
    (acc, [field, { sourceType, ...item }]) => {
      const domainType =
        item.domainType ?? elasticSourceTypeToDomainType(sourceType);

      acc[field] = toMappingItem({
        ...item,
        source: {
          type: sourceType,
        },
        domainType,
        domainTypeAttributes:
          domainType === 'DateString' && isNil(item.domainTypeAttributes)
            ? { format: 'yyyy-MM-dd' }
            : item.domainTypeAttributes,
      });

      return acc;
    },
    {}
  );
};

export const generateElasticTemplateMapping = (
  elasticMapping: Record<string, ElasticMappingItem>
) => {
  return {
    dynamic: 'strict',
    dynamic_templates: [],
    properties: Object.entries(elasticMapping).reduce(
      (acc, [field, item]) => ({
        ...acc,
        [field]: {
          type: item.sourceType,
          format: item.sourceType === 'date' ? 'yyyy-MM-dd' : undefined,
          fields:
            item.sourceType === 'text' && !item.isNotSortable
              ? {
                  keyword: {
                    ignore_above: 256,
                    type: 'keyword',
                  },
                }
              : undefined,
        },
      }),
      {}
    ),
  };
};

export function dumpElasticMapping(params: {
  rawMapping: Record<string, ElasticMappingItem>;
  dumpRaw: boolean;
  dumpElasticTemplate: boolean;
}): void {
  const { rawMapping, dumpRaw, dumpElasticTemplate } = params;

  if (dumpRaw) {
    console.log('raw mapping', rawMapping);
  }

  if (dumpElasticTemplate) {
    const elasticTemplateMapping = generateElasticTemplateMapping(rawMapping);
    console.log(JSON.stringify(elasticTemplateMapping));
  }
}

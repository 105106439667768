import type { DataItemId, MappingItem } from '@pn/core/domain/data';
import { geometryToGeoShape, type GeoShape } from '@pn/core/domain/geography';
import {
  isWKTString,
  type WKTString,
  wktToGeoShape,
} from '@pn/core/domain/types';
import { isGeoJSONGeometry } from '@pn/core/utils/geospatial';
import { isNil } from 'lodash-es';

export type ApiDataItem = {
  internal_id: DataItemId;
  geometry: GeoJSON.Geometry | WKTString | undefined;
  [key: string]: unknown;
};

export function apiGeometryToGeoShape(
  apiGeometry: ApiDataItem['geometry']
): GeoShape | undefined {
  if (isNil(apiGeometry)) return undefined;
  if (isGeoJSONGeometry(apiGeometry)) return geometryToGeoShape(apiGeometry);
  if (isWKTString(apiGeometry)) return wktToGeoShape(apiGeometry);
}

/**
 * All source mapping items must be derived from this type.
 */
export type RawMappingItem = Omit<MappingItem, 'source' | 'domainType'> & {
  domainType?: MappingItem['domainType']; // can be defined explicitly when necessary
  domainTypeAttributes?: MappingItem['domainTypeAttributes'];
};

export type ElasticSourceType =
  | 'boolean'
  | 'integer'
  | 'double'
  | 'date'
  | 'keyword'
  | 'text'
  | 'geo_shape';

export type ElasticMappingItem = RawMappingItem & {
  sourceType: ElasticSourceType;
};

export type PostgresSourceType =
  | 'bool'
  | 'int'
  | 'serial'
  | 'float'
  | 'varchar'
  | 'text'
  | 'date'
  | 'timestamp'
  | 'geometry'
  | 'json'
  | 'jsonb';

export type PostgresMappingItem = RawMappingItem & {
  sourceType: PostgresSourceType;
};

export type ParquetSourceType =
  | 'Boolean'
  | 'Float32'
  | 'Float64'
  | 'Int8'
  | 'Int16'
  | 'Int32'
  | 'Int64'
  | 'String'
  | 'WKTString' // special type
  | 'Date'
  | 'Null';

export type ParquetMappingItem = {
  field: string;
  data_type: ParquetSourceType;
  is_not_renderable?: true | null;
};

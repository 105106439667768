import { Query } from '@pn/core/domain/query';
import { IIntelMappableDataProvider } from '@pn/core/providers/intel/ports';
import { pnApiClient } from '@pn/services/api/pnApiClient';
import { isNil, orderBy } from 'lodash-es';
import { ApiIntelItem } from './types';
import {
  IntelActivityItem,
  IntelQuery,
} from '@pn/core/domain/intel/intel-activity-item';
// TODO: Undo the sins
export const requestData = async (
  intelQuery: IntelQuery,
  geographyFilter: GeoJSON.Geometry | null,
  province: string | null
) => {
  const { query } = intelQuery;
  let dataType = query.dataType;

  let endpointType = query.dataType;
  if (
    endpointType === 'well_spuds' ||
    endpointType === 'well_on_prods' ||
    endpointType === 'well_rig_releases'
  ) {
    endpointType = 'well_licenses';
    dataType = 'well_licenses';
  }

  // TODO: ugly hack for the joined table of this endpoint
  let modifiedFilters = [...query.filters];
  if (!isNil(province) && isNil(intelQuery.linkedReportId)) {
    if (dataType === 'well_licenses') {
      modifiedFilters.push({
        field: 'wellbores.province',
        operator: '=',
        value: province,
        unitSystem: undefined,
      });
    } else if (dataType === 'well_license_transfers') {
      modifiedFilters.push({
        field: 'license_transfers_by_company.province',
        operator: '=',
        value: province,
        unitSystem: undefined,
      });
    } else if (dataType === 'land_results' || dataType === 'land_postings') {
      modifiedFilters.push({
        field: 'market_intel_land_postings.province',
        operator: '=',
        value: province,
        unitSystem: undefined,
      });
    } else {
      modifiedFilters.push({
        field: 'province',
        operator: '=',
        value: province,
        unitSystem: undefined,
      });
    }
  }

  if (dataType === 'aer_transfers') {
    const provinceFilter = modifiedFilters.find(
      ({ field }) => field === 'province'
    );
    if (!isNil(provinceFilter)) {
      if (provinceFilter?.value !== 'AB') {
        return [];
      }
      modifiedFilters = modifiedFilters.filter(
        ({ field }) => field !== 'province'
      );
    }
  }

  if (!isNil(geographyFilter) && isNil(intelQuery.linkedReportId)) {
    if (
      dataType === 'well_licenses' ||
      dataType === 'liquid_ip3' ||
      dataType === 'liquid_ip12' ||
      dataType === 'gas_ip3' ||
      dataType === 'gas_ip12'
    ) {
      modifiedFilters.push({
        field: 'wellbores.geom',
        operator: 'intersects',
        value: geographyFilter as any, // TODO: filters dont yet handle geo_shape
        unitSystem: undefined,
      });
    } else if (dataType === 'mineral_rights_transfers') {
      modifiedFilters.push({
        field: 'geom',
        operator: 'intersects',
        value: geographyFilter as any, // TODO: filters dont yet handle geo_shape
        unitSystem: undefined,
      });
    }
  }

  const payload = {
    columns: [],
    conditions: modifiedFilters,
    conditions_link_operator: query.filtersLinkOperator,
  };

  if (!isNil(geographyFilter)) {
    Object.assign(payload, {
      geo_shape: geographyFilter,
    });
  }
  if (
    dataType === 'liquid_ip3' ||
    dataType === 'liquid_ip12' ||
    dataType === 'gas_ip3' ||
    dataType === 'gas_ip12'
  ) {
    const apiIntelData = await pnApiClient.request<ApiIntelItem[]>({
      url: `v2/wells/top_wells_by_ip/${dataType}`,
      method: 'POST',
      payload,
    });
    return apiIntelData.map((item) => {
      const activityItem: IntelActivityItem = {
        ...item,
        id: item.wellbore_uwi,
        activityItemIds: [item.wellbore_uwi as string],
      };
      return activityItem;
    });
  } else {
    const apiIntelData = await pnApiClient.request<ApiIntelItem[]>({
      url: `v2/data/${endpointType}`,
      method: 'POST',
      payload,
    });

    return processGenericData(dataType, apiIntelData);
  }
};

export const requestTopWells = async (
  query: Query,
  geographyFilter: GeoJSON.Geometry | null,
  province: string | null,
  type: string | null,
  limit = 30
) => {
  let conditions = query.filters;
  if (!isNil(geographyFilter)) {
    conditions = [
      {
        field: 'wellbores.geom',
        operator: 'intersects',
        value: geographyFilter as any, // TODO: filters dont yet handle geo_shape
        unitSystem: undefined,
      },
      ...conditions,
    ];
  } else if (!isNil(province)) {
    conditions = [
      {
        field: 'wellbores.province',
        operator: '=',
        value: province,
        unitSystem: undefined,
      },
      ...conditions,
    ];
  }

  const payload = {
    conditions: conditions,
    conditions_link_operator: query.filtersLinkOperator,
  };
  const apiIntelData = await pnApiClient.request<ApiIntelItem[]>({
    url: `v2/wells/top_producing/${type}?limit=${limit}`,
    method: 'POST',
    payload,
  });

  return apiIntelData.map((item) => {
    const activityItem: IntelActivityItem = {
      ...item,
      id: item.wellbore_uwi,
      activityItemIds: [item.wellbore_uwi as string],
    };
    return activityItem;
  });
};

export const requestWellMismatches = async (
  query: Query,
  geographyFilter: GeoJSON.Geometry | null,
  province: string | null
) => {
  const modifiedFilters = [...query.filters];

  if (!isNil(geographyFilter)) {
    modifiedFilters.push({
      field: 'wellbores.geom',
      operator: 'intersects',
      value: geographyFilter as any, // TODO: filters dont yet handle geo_shape
      unitSystem: undefined,
    });
  }
  if (!isNil(province)) {
    modifiedFilters.push({
      field: 'licenses.province',
      operator: '=',
      value: province,
      unitSystem: undefined,
    });
  }

  const payload = {
    columns: [],
    conditions: modifiedFilters,
    conditions_link_operator: query.filtersLinkOperator,
  };

  const apiIntelData = await pnApiClient.request<ApiIntelItem[]>({
    url: `v2/data/well_licence_mismatches`,
    method: 'POST',
    payload,
  });

  const mappedData = apiIntelData.map((item) => {
    const activityItem: IntelActivityItem = {
      ...item,
      id: item.license_id,
      activityItemIds: item.activityItemIds as string[],
      uwis: item.activityItemIds.join(', '),
    };
    return activityItem;
  });

  return orderBy(mappedData, 'licenses.license_date', 'desc');
};

export const apiIntelDynamicDataProvider: IIntelMappableDataProvider = {
  getData: requestData,
  getTopWells: requestTopWells,
  getWellMismatches: requestWellMismatches,
};

function processGenericData(
  dataType: string,
  apiData: ApiIntelItem[]
): IntelActivityItem[] {
  return apiData.map((item, index) => {
    let activityItemIds: string[] = [];

    const extraProperties: any = {};
    // TODO: this needs to happen on the DB side
    switch (dataType) {
      case 'well_licenses':
      case 'well_spuds':
      case 'well_rig_releases':
      case 'well_on_prods':
        activityItemIds = item.uwis as string[];
        break;
      case 'well_license_transfers':
        activityItemIds = item.wellbore_uwi_list as string[];
        break;
      case 'land_postings':
      case 'land_results':
        item.id = item.contract_no + item.notice_date;
        activityItemIds = item.land_id_list as string[];
        extraProperties.segments = item.land_id_list?.length ?? 0;

        try {
          extraProperties.zones_json = item.zone_description_list.join(' ');
        } catch (err) {
          console.error('Error parsing zones');
          extraProperties.zones_json = '';
        }

        break;
      case 'mineral_rights_transfers':
        activityItemIds = item.petroninja_land_id_list as string[];
        break;
      case 'aer_transfers':
        activityItemIds = item.grid_ids.map(({ land_id }: any) => land_id);
        item.id = item.last_status_date + index + 'aer_transfers';
        break;
      default:
        activityItemIds = item.activityItemIds as string[];
    }

    const updatedItem: IntelActivityItem = {
      ...item,
      ...extraProperties,
      activityItemIds,
    };

    return updatedItem;
  });
}

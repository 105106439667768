// this is the initial object that intel activity items will eventually be based on
// working to consolidate these into a single domain object with dynamic storage
import { colors } from '@mui/material';
import { muiColorPalette } from '@pn/services/color/colorPalettes';
import { addAlpha } from '@pn/services/utils/color';
import { isEmpty, isNil, startCase } from 'lodash-es';
import { pnWorkspaceItems } from '@pn/core/storage/workspace/pnWorkspaceItems';
import { generateTemporaryId } from '@pn/core/utils/id.ts';
import { findOrThrow } from '@pn/core/utils/logic.ts';
import { buildQuery, DataMultiSelectionReason, Query } from '../query';
import { User } from '@pn/core/domain/user.ts';
import { createWorkspaceItem, WorkspaceItem } from '../workspace';

export type IntelActivityItem = {
  id: string;
  activityItemIds: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export type IntelQueryWorkspaceItemDataType =
  | 'wells'
  | 'grids'
  | 'mineral_rights';

export type IntelQueryType =
  | 'well_licenses'
  | 'well_spuds'
  | 'well_rig_releases'
  | 'well_on_prods'
  | 'confidential_wells'
  | 'declassified_wells'
  | 'land_postings'
  | 'land_results'
  | 'aer_transfers'
  | 'mineral_rights_transfers'
  | 'well_license_transfers'
  | 'wells_by_liquid_production'
  | 'wells_by_production'
  | 'wells_by_gas_production'
  | 'well_licence_mismatches'
  | 'liquid_ip3'
  | 'liquid_ip12'
  | 'gas_ip3'
  | 'gas_ip12';

export function isWellLicenceType(type: string): type is 'well_licenses' {
  return (
    type === 'well_licenses' ||
    type === 'well_spuds' ||
    type === 'well_rig_releases' ||
    type === 'well_on_prods'
  );
}

const DefaultNames: Record<IntelQueryType, string> = {
  well_licenses: 'Well Licences',
  well_spuds: 'Well Spuds',
  well_rig_releases: 'Well Rig Releases',
  well_on_prods: 'Well On Production',
  aer_transfers: 'AER Transfers',
  mineral_rights_transfers: 'Mineral Rights Transfers',
  well_license_transfers: 'Well Licence Transfers',
  wells_by_liquid_production: 'Top Liquid Wells',
  wells_by_gas_production: 'Top Gas Wells',
  wells_by_production: 'Top Wells (BOE/d)',
  well_licence_mismatches: 'Well Mismatches',
  liquid_ip3: 'Top Liquid IP3 Wells',
  liquid_ip12: 'Top Liquid IP12 Wells',
  gas_ip3: 'Top Gas IP3 Wells',
  gas_ip12: 'Top Gas IP12 Wells',
  land_postings: 'Land Postings',
  land_results: 'Land Results',
  confidential_wells: 'Confidential Wells',
  declassified_wells: 'Declassified Wells',
};

export function generateDefaultItemName(
  dataType: IntelQueryType | string
): string {
  const name = DefaultNames[dataType as IntelQueryType];
  return name ? name : startCase(dataType);
}

export type IntelQuery = {
  id: string;
  name: string;
  disabled: boolean;
  editingDisabled: boolean;
  mapPlottingEnabled: boolean;
  dataType: IntelQueryWorkspaceItemDataType; // used to link up with source workspace item
  query: Query;
  intelData: IntelActivityItem[];
  checkedIntelDataIds: IntelActivityItem['id'][];
  isFetching: boolean;
  failedToFetch?: boolean;
  linkedReportId?: string;
  hideFromWorkspace?: boolean;
  _needsProcessing?: boolean;
  _needsVisualProcessing?: boolean;
  _avoidLoadingUntilVisible?: boolean; // optimization flag
};

export function buildIntelQueryObject(
  dataType: IntelQueryType,
  params: Partial<Query>
): Query {
  return buildQuery({ id: generateTemporaryId(), ...params, dataType });
}

export type IntelQueryParams = {
  id?: string;
  query: Query;
  name?: string;
  dataType: IntelQueryWorkspaceItemDataType;
  _avoidLoadingUntilVisible?: boolean;
  linkedReportId?: string;
  editingDisabled?: boolean;
  plotOnMapWhenAdded?: boolean;
};

export function createIntelQuery(params: IntelQueryParams): IntelQuery {
  const {
    id = generateTemporaryId(),
    query,
    name = generateDefaultItemName(query.dataType),
    dataType,
    linkedReportId,
    _avoidLoadingUntilVisible,
    plotOnMapWhenAdded = false,
  } = params;

  return {
    id,
    name,
    disabled: false,
    editingDisabled: false,
    mapPlottingEnabled: plotOnMapWhenAdded,
    dataType,
    query,
    intelData: [],
    checkedIntelDataIds: [],
    isFetching: false,
    _needsProcessing: true,
    _needsVisualProcessing: plotOnMapWhenAdded,
    _avoidLoadingUntilVisible,
    linkedReportId,
    hideFromWorkspace: !isNil(linkedReportId),
  };
}

const gridsWorkspaceItem = findOrThrow(
  pnWorkspaceItems,
  ({ id }) => id === 'grids'
);

const mineralRightsWorkspaceItem = findOrThrow(
  pnWorkspaceItems,
  ({ id }) => id === 'mineral_rights'
);

const wellsWorkspaceItem = findOrThrow(
  pnWorkspaceItems,
  ({ id }) => id === 'wells'
);

export function convertIntelQueryToWorkspaceItem(
  intelQuery: IntelQuery,
  user?: User | undefined
): WorkspaceItem {
  const { id, name } = intelQuery;
  const checkedIds = getCheckedIntelDataItemIds(intelQuery);
  const requestedIds = isEmpty(checkedIds)
    ? intelQuery.intelData.flatMap((item) => item.activityItemIds)
    : checkedIds;
  let sourceWorkspaceItem = null;
  switch (intelQuery.dataType) {
    case 'grids':
      sourceWorkspaceItem = gridsWorkspaceItem;
      break;
    case 'mineral_rights':
      sourceWorkspaceItem = mineralRightsWorkspaceItem;
      break;
    case 'wells':
      sourceWorkspaceItem = wellsWorkspaceItem;
      break;
    default:
      throw new Error('Unknown data type');
  }

  const userToUse: User =
    user ??
    ({
      id: '1',
      email: '',
      name: { firstName: '', lastName: '' },
      company: '',
      title: '',
      acceptMail: false,
    } as User);

  return createWorkspaceItem(
    {
      source: 'item',
      sourceItem: sourceWorkspaceItem,
      id,
      isTemporary: true,
      name,
      origin: 'boe_intel',
      queryOptions: {
        requestedIds,
        multiSelectionReason: DataMultiSelectionReason.List,
        ignoreLimit: true,
      },
      extraStyle: {
        color: getDefaultColor(intelQuery.query.dataType),
      },
    },
    userToUse
  );
}

function getDefaultColor(dataType: string) {
  switch (dataType) {
    case 'well_licenses':
    case 'well_spuds':
    case 'well_rig_releases':
    case 'well_on_prods':
      return muiColorPalette.getStaggeredColorByIndex(1);
    case 'aer_transfers':
      return addAlpha(muiColorPalette.getStaggeredColorByIndex(2), 0.8);
    case 'mineral_rights_transfers':
      return addAlpha(muiColorPalette.getStaggeredColorByIndex(4), 0.8);
    case 'well_license_transfers':
      return muiColorPalette.getStaggeredColorByIndex(3);
    case 'land_postings':
      return addAlpha(muiColorPalette.getStaggeredColorByIndex(6), 0.8);
    case 'land_results':
      return addAlpha(muiColorPalette.getStaggeredColorByIndex(0), 0.8);
    case 'wells_by_liquid_production':
      return colors.blue[500];
    case 'wells_by_gas_production':
      return colors.red[400];
    default:
      return '#000';
  }
}

export function getQueriesForProcessing(queries: IntelQuery[]): IntelQuery[] {
  return queries.filter(
    (query) =>
      query._needsProcessing &&
      !query.isFetching &&
      !query.disabled &&
      !query._avoidLoadingUntilVisible
  );
}

export function getQueriesToAddOrUpdate(queries: IntelQuery[]): IntelQuery[] {
  return queries.filter(
    (query) =>
      query.mapPlottingEnabled &&
      query._needsVisualProcessing &&
      !query.isFetching &&
      !query.disabled &&
      !isEmpty(query.intelData)
  );
}

export function getQueriesToRemove(queries: IntelQuery[]): IntelQuery[] {
  return queries.filter((query) => {
    const shouldRemove =
      !query.mapPlottingEnabled &&
      query._needsVisualProcessing &&
      !query.isFetching &&
      !query.disabled;
    return shouldRemove || isEmpty(query.intelData);
  });
}

export function getCheckedIntelDataItemIds(
  intelQuery: IntelQuery
): IntelActivityItem['id'][] {
  const checkedItems = intelQuery.intelData.filter((item) =>
    intelQuery.checkedIntelDataIds.includes(item.id)
  );
  return checkedItems.flatMap((item) => item.activityItemIds);
}

export function getCheckedIntelData(
  intelQuery: IntelQuery
): IntelActivityItem[] {
  return intelQuery.intelData.filter((item) =>
    intelQuery.checkedIntelDataIds.includes(item.id)
  );
}
